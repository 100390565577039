export default [
  {
    path: 'reports/',
    component: () => import('@/views/pages/reports/ReportsList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Relatórios',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'reports/new',
    component: () => import('@/views/pages/reports/ReportsNew'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Relatórios',
          to: '/reports',
          exact: true,
        },
        {
          text: 'Adicionar',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'reports/:id/edit',
    component: () => import('@/views/pages/reports/ReportsEdit'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Relatórios',
          to: '/reports',
          exact: true,
        },
        {
          text: 'Editar',
          disabled: true,
        },
      ],
    },
  },

]
