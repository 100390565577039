<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-btn
      icon
      small
      :class="{ 'red--text': hover }"
    >
      <v-icon
        small
        @click="$emit('click')"
      >
        mdi-delete
      </v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
  export default {
    name: 'TableButtonDelete',
  }
</script>
