export default [
  {
    path: 'activity-branches/',
    component: () => import('@/views/pages/activity-branches/ActivityBranchesList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Ramos de Atividade',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'activity-branches/new',
    component: () => import('@/views/pages/activity-branches/ActivityBranchesNew'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Ramos de Atividade',
          to: '/activity-branches',
          exact: true,
        },
        {
          text: 'Adicionar',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'activity-branches/:id/edit',
    component: () => import('@/views/pages/activity-branches/ActivityBranchesEdit'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Ramos de Atividade',
          to: '/activity-branches',
          exact: true,
        },
        {
          text: 'Editar',
          disabled: true,
        },
      ],
    },
  },
]
