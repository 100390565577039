export default [
  {
    path: "sectors/",
    component: () => import("@/views/pages/sectors/SectorsList"),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: "Setores",
          disabled: true
        }
      ]
    }
  }
];
