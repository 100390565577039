export default [
  {
    path: 'complementary-report/',
    component: () =>
      import('@/views/pages/complementary-report/ComplementaryReport'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Relatório Complementar',
          disabled: true,
        },
      ],
    },
  },
]
