import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'
import App from '@/App.vue'
import router from '@/router/index'
import store from '@/store/index'
import vuetify from '@/plugins/vuetify'
import snackbar from '@/plugins/snackbar'
import messages from '@/plugins/messages'
import handlerApiError from '@/plugins/handler-api-error'
import '@/plugins/vee-validate'
import '@/utils/components'
import '@/utils/filters'

// import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client'

Vue.use(snackbar)
Vue.use(messages)
Vue.use(handlerApiError)

// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: SocketIO(process.env.VUE_APP_API, {
//     query: 'userId=' + store.state.user.user.id,
//   }),
// }))

Vue.directive('mask', VueMaskDirective)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
