<template>
  <router-link
    :to="href"
    class="text-decoration-none"
  >
    <v-hover
      v-slot="{ hover }"
    >
      <v-btn
        icon
        small
        :class="{ 'info--text': hover }"
      >
        <v-icon
          small
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-hover>
  </router-link>
</template>

<script>
  export default {
    name: 'TableButtonEdit',

    props: {
      href: {
        type: String,
        default: '',
      },
    },

  }
</script>
