export default {
  namespaced: true,
  state: {
    checkLoginInterval: null,
  },
  mutations: {
    SET_CHECK_LOGIN_INTERVAL (state, payload) {
      state.checkLoginInterval = payload
    },
  },
  actions: {
    clearCheckLoginInterval ({ state }) {
      clearInterval(state.checkLoginInterval)
    },
  },
}
