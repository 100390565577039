export default {
  namespaced: true,
  state: {
    drawer: null,
    snackbar: {
      show: false,
      color: null,
      message: null,
      timeout: 5000,
    },
  },
  mutations: {
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_SNACKBAR (state, { show = true, color = null, message = null, timeout = 5000 }) {
      state.snackbar.show = show
      state.snackbar.color = color
      state.snackbar.message = message
      state.snackbar.timeout = timeout
    },
  },
}
