<template>
  <v-btn
    color="success"
    class="white--text"
    :loading="loading"
    :text="text"
    @click="$emit('click')"
  >
    <v-icon
      class="mr-2"
    >
      mdi-content-save
    </v-icon>
    Salvar
  </v-btn>
</template>

<script>
  export default {
    name: 'ButtonSave',

    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      text: {
        type: Boolean,
        default: false,
      },
    },

  }
</script>
