<template>
  <v-btn
    class="mr-2"
    outlined
    :link="false"
    :to="to"
    active-class="asdsad"
  >
    <v-icon class="mr-2">
      mdi-chevron-left
    </v-icon>
    Voltar
  </v-btn>
</template>

<script>
  export default {

    props: {
      to: {
        type: String,
        default: '/',
      },
    },

  }
</script>

<style scoped>
.v-btn--active::before {
  opacity: 0;
}
</style>