export default [
  {
    path: 'users/',
    component: () => import('@/views/pages/users/UsersList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Usuários',
          disabled: true,
        },
      ],
    },
  },
  {
    name: 'Adicionar Usuário',
    path: 'users/new',
    component: () => import('@/views/pages/users/UsersNew'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Usuários',
          to: '/users',
          exact: true,
        },
        {
          text: 'Adicionar',
          disabled: true,
        },
      ],
    },
  },
  {
    name: 'Editar Usuário',
    path: 'users/:id/edit',
    component: () => import('@/views/pages/users/UsersEdit'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Usuários',
          to: '/users',
          exact: true,
        },
        {
          text: 'Editar',
          disabled: true,
        },
      ],
    },
  },
]
