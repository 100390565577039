import Vue from 'vue'

import CorePageTitle from '@/components/core/CorePageTitle'

import ButtonNew from '@/components/button/ButtonNew'
import ButtonCancel from '@/components/button/ButtonCancel'
import ButtonSave from '@/components/button/ButtonSave'
import ButtonBack from '@/components/button/ButtonBack'

import TableButtonEdit from '@/components/table/TableButtonEdit'
import TableButtonDelete from '@/components/table/TableButtonDelete'
import TableButtonView from '@/components/table/TableButtonView'
import TableButtonPdf from '@/components/table/TableButtonPdf'

Vue.component('core-page-title', CorePageTitle)

Vue.component('btn-new', ButtonNew)
Vue.component('btn-cancel', ButtonCancel)
Vue.component('btn-save', ButtonSave)
Vue.component('btn-back', ButtonBack)

Vue.component('table-btn-edit', TableButtonEdit)
Vue.component('table-btn-delete', TableButtonDelete)
Vue.component('table-btn-view', TableButtonView)
Vue.component('table-btn-pdf', TableButtonPdf)
