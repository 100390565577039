<template>
  <v-row
    v-if="$route.meta.breadcrumb"
    id="app-title"
    no-gutters
    class="mb-3"
  >
    <v-col class="white no-gutters">
      <v-breadcrumbs
        :items="$route.meta.breadcrumb || []"
        class="py-3"
      >
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'CorePageTitle',

    data: () => {
      return {

      }
    },

  }
</script>

<style lang="sass">
  #app-title
    border-bottom: 1px solid #d8dbe0 !important
</style>
