export default [
  {
    path: 'customers/',
    component: () => import('@/views/pages/customers/CustomersList'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Clientes',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'customers/new',
    component: () => import('@/views/pages/customers/CustomersNew'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Clientes',
          to: '/customers',
          exact: true,
        },
        {
          text: 'Adicionar',
          disabled: true,
        },
      ],
    },
  },
  {
    path: 'customers/:id/edit',
    component: () => import('@/views/pages/customers/CustomersEdit'),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: 'Clientes',
          to: '/customers',
          exact: true,
        },
        {
          text: 'Editar',
          disabled: true,
        },
      ],
    },
  },
]
