<template>
  <v-btn
    color="grey"
    class="white--text"
    :text="text"
    @click="$emit('click')"
  >
    <v-icon
      class="mr-2"
    >
      mdi-cancel
    </v-icon>
    Cancelar
  </v-btn>
</template>

<script>
  export default {
    name: 'ButtonCancel',

    props: {
      text: {
        type: Boolean,
        default: false,
      },
    },

  }
</script>
