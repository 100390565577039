<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-btn
      icon
      small
      :loading="loading"
      :class="{ 'red--text': hover }"
    >
      <v-icon
        small
        @click="$emit('click')"
      >
        mdi-file-download
      </v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
