export default {
  namespaced: true,
  state: {
    token: null,
    customer: null,
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_CUSTOMER(state, payload) {
      state.customer = payload
    },
  },
}
