import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store/index'

import reports from './modules/reports'
import complementaryReport from './modules/complementary-report'
import users from './modules/users'
import customers from './modules/customers'
import activityBranches from './modules/activity-branches'
import sectors from './modules/sectors'
import configurations from './modules/configurations'
import checklistItems from './modules/checklist-items'
import customerTasks from './modules/customer-tasks'

Vue.use(Router)

const routes = [
  {
    name: 'Login',
    path: '/login',
    component: () => import('@/views/pages/Login'),
    meta: { redirectIfAuth: true },
  },

  {
    path: '/',
    component: () => import('@/views/Index'),
    children: [
      {
        name: 'Dashboard',
        path: '',
        component: () => import('@/views/pages/Dashboard'),
        meta: { auth: true },
      },
      ...users,
      ...customers,
      ...activityBranches,
      ...reports,
      ...complementaryReport,
      ...sectors,
      ...configurations,
      ...checklistItems,
      ...customerTasks,
    ],
  },
  {
    path: '/acesso-cliente',
    component: () => import('@/views/IndexCustomer'),
    children: [
      {
        name: 'Login Cliente',
        path: 'login',
        component: () => import('@/views/pages-customer/Login'),
        meta: { redirectIfAuthCustomer: true },
      },
      {
        path: 'dashboard',
        alias: '',
        component: () => import('@/views/pages-customer/dashboard/Dashboard'),
        meta: { authCustomer: true },
      },
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  var token = store.state.user.token
  var tokenCustomer = store.state.customer.token

  if (to.matched.some(record => record.meta.auth)) {
    if (!token) {
      return next({ path: '/login' })
    } else {
      return next()
    }
  } else if (to.matched.some(record => record.meta.redirectIfAuth)) {
    if (token) {
      return next({ path: '/' })
    } else {
      return next()
    }
  }

  if (to.matched.some(record => record.meta.authCustomer)) {
    if (!tokenCustomer) {
      return next({ path: '/acesso-cliente/login' })
    } else {
      return next()
    }
  } else if (to.matched.some(record => record.meta.redirectIfAuthCustomer)) {
    if (tokenCustomer) {
      return next({ path: '/acesso-cliente/dashboard' })
    } else {
      return next()
    }
  }

  return next()
})

export default router
