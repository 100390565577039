import store from '@/store/index'

export default {
  install: (Vue) => {
    Vue.prototype.$snackbar = {

      show: function ({ message = null, color = null, timeout = 5000 }) {
        store.commit('layout/SET_SNACKBAR', { message, color, timeout })
      },

    }
  },
}
