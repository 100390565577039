export default [
  {
    path: "configurations/",
    component: () => import("@/views/pages/configurations/Configurations"),
    meta: {
      auth: true,
      breadcrumb: [
        {
          text: "Configurações",
          disabled: true
        }
      ]
    }
  }
];
