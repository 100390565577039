import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import layout from '@/store/modules/layout'
import user from '@/store/modules/user'
import customer from '@/store/modules/customer'
import general from '@/store/modules/general'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    layout,
    user,
    customer,
  },
  actions: {
    logout({ commit, dispatch }) {
      commit('user/SET_TOKEN', null)
      dispatch('general/clearCheckLoginInterval', null, { root: true })
    },
    logoutCustomer({ commit }) {
      commit('customer/SET_TOKEN', null)
    },
  },
  plugins: [
    createPersistedState({
      key: 'vuex',
      reducer(val) {
        return {
          user: val.user,
          customer: val.customer
        }
      },
    }),
  ],
})
