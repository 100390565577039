import messages from '@/utils/messages'

export default {
  install: (Vue) => {
    Vue.prototype.$messages = {
      _: function (key) {
        return messages[key]
      },
    }
  },
}
