<template>
  <v-btn
    color="success"
    class="white--text"
    :to="to"
  >
    <v-icon
      class="mr-2"
    >
      mdi-plus
    </v-icon>
    Adicionar
  </v-btn>
</template>

<script>
  export default {
    name: 'ButtonNew',

    props: {
      to: {
        type: String,
        default: '/',
      },
    },

  }
</script>
