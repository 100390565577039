export default {
  namespaced: true,
  state: {
    token: null,
    user: null,
  },
  getters: {
    isAdmin: state => {
      const adminSearch = state.user.roles.find(r => r.id === 1)
      return !!adminSearch
    },
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload
    },
    SET_USER(state, payload) {
      state.user = payload
    },
  },
}
