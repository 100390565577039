import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pt from '@/utils/vuetify-pt-br'
import '@/sass/overrides.sass'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#0A0933',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2A7DB1',
        success: '#36AA49',
        warning: '#FDDA1C',
      },
    },
  },
  lang: {
    locales: { pt },
    current: 'pt',
  },
})
